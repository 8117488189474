<template>
  <b-tabs
    pills
  >
    <!-- : Information -->
    <b-tab active>
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="16"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">Informacion</span>
      </template>
      <account-setting-information
        :user-data="userData"
        :is-loading-account-info="isLoadingAccountInfo"
        class="mt-2 pt-75"
      />
    </b-tab>

    <!-- Tab: Password -->
    <b-tab>
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="d-none d-sm-inline">Cambiar Password</span>
      </template>
      <account-setting-password 
        :user-id="currentUser.id"
        class="mt-2 pt-75" 
      />
    </b-tab>

  </b-tabs>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingInformation from './AccountSettingInformation.vue'
import { getUserData } from '@/auth/utils'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    AccountSettingInformation,
    AccountSettingPassword,
  },
  setup() {
    const userData = ref({})
    const currentUser = getUserData();
    const isLoadingAccountInfo = ref(true)

    // Use toast
    const toast = useToast()

    const fechUserData = () => {

      isLoadingAccountInfo.value = true

      axios
      .post(`${process.env.VUE_APP_BASE_URL}/user_info`, 
        { id: currentUser.id }
      )
      .then(response => {
        userData.value = response.data.result
        // formatPermission()
      })
      .catch(error => {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching user ifno',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        isLoadingAccountInfo.value = false
      })
    }

    fechUserData()

    return {
      userData,
      isLoadingAccountInfo,

      currentUser,
    }
  },
}
</script>

<style>

</style>
